export const state = {
  currentUser: JSON.parse(
    localStorage.getItem("userdata")
      ? localStorage.getItem("userdata") != "undefined"
        ? localStorage.getItem("userdata")
        : "{}"
      : "{}"
  ),
};

export const mutations = {
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue;
  },
};

export const getters = {
  currentUser() {
    return JSON.parse(
      localStorage.getItem("userdata")
        ? localStorage.getItem("userdata") != "undefined"
          ? localStorage.getItem("userdata")
          : "{}"
        : "{}"
    );
  },
  // Whether the user is currently logged in.
  loggedIn(state) {
    return !!state.currentUser && JSON.stringify(state.currentUser) != "{}";
  },
};

export const actions = {};

import { createStore } from "vuex";

import modules from "./modules";

const store = createStore({
  state() {
    return {
      loading: false,
    };
  },
  actions: {
    setGlobalLoading({ commit }, loading) {
      commit("SET_LOADING", loading);
      if (loading) {
        window.document.body.classList.add("is-loading");
        window.document.getElementById("loading-container").style.display =
          "flex";
      } else {
        window.document.body.classList.remove("is-loading");
        window.document.getElementById("loading-container").style.display =
          "none";
      }
    },
  },
  mutations: {
    SET_LOADING(state, loading) {
      state.loading = loading;
    },
  },
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== "production",
});

export default store;
